import shortid from 'shortid'

const onstageSlides = [
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel singing with a band',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Orchestra Playing',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'Open Chambers: Hindemith & Shostakovich',
    role: 'Soprano II',
    company: 'Opera 5'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'Die Fledermaus',
    role: 'Rosalinda',
    company: 'Opera 5'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    altText:
      'Rachel Krehm as Rosalinda in Die Fledermaus. Image by Emily Ding Photography.',
    imagePos: '50% 50%'
  },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'Don Giovanni',
    role: 'Donna Anna',
    company: 'C.O.S.I. Opera (Italy)'
  },
  { id: shortid.generate(), hasImage: true, imagePos: '50% 50%' },
  { id: shortid.generate(), hasImage: true, imagePos: '50% 50%' },
  { id: shortid.generate(), hasImage: true, imagePos: '50% 50%' },
  { id: shortid.generate(), hasImage: true, imagePos: '50% 50%' },
  { id: shortid.generate(), hasImage: true, imagePos: '50% 50%' },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'Whose Opera Is It Anyway?!',
    role: 'Improv Show',
    company: 'Bad Dog Comedy Club'
  },
  { id: shortid.generate(), hasImage: true, imagePos: '50% 50%' },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText: `Cast of 'Whose Opera Is It Anyway?!'. Image by Dahlia Katz Photography.`
  },
  { id: shortid.generate(), hasImage: true, imagePos: '50% 50%' },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'Il Segreto di Susanna',
    role: 'Susanna',
    company: 'Opera 5'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Susanna in Il Segreto di Susanna. Image by Emily Ding Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Susanna in Il Segreto di Susanna. Image by Emily Ding Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Susanna in Il Segreto di Susanna. Image by Emily Ding Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Susanna in Il Segreto di Susanna. Image by Emily Ding Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Susanna in Il Segreto di Susanna. Image by Emily Ding Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'Hin und Zurück',
    role: 'Hélène',
    company: 'Opera 5'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Hélène in Hin und Zurück. Image by Lexie Mitchell Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Hélène in Hin und Zurück. Image by Lexie Mitchell Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Hélène in Hin und Zurück. Image by Lexie Mitchell Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Hélène in Hin und Zurück. Image by Lexie Mitchell Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'A Shakespeare Serenade',
    company: 'Toronto Summer Music Festival'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 0%',
    altText: 'Rachel Krehm in A Shakespeare Seranade. Image by James Ireland.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText: 'Rachel Krehm in A Shakespeare Seranade. Image by James Ireland.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 0%',
    altText: 'Rachel Krehm in A Shakespeare Seranade. Image by James Ireland.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText: 'Rachel Krehm in A Shakespeare Seranade. Image by James Ireland.'
  },
  {
    id: shortid.generate(),
    hasImage: false,
    title: 'La Voix Humaine',
    role: 'Elle',
    company: 'Opera 5'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Elle in La Voix Humaine. Image by Lexie Mitchell Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Elle in La Voix Humaine. Image by Lexie Mitchell Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Elle in La Voix Humaine. Image by Lexie Mitchell Photography.'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText:
      'Rachel Krehm as Elle in La Voix Humaine. Image by Lexie Mitchell Photography.'
  }
]

export default onstageSlides
