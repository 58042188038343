import React from 'react'

import styled from 'styled-components'
import { styles } from '../utils/theme'

import MenuToggleAndOverlay from './MenuToggleAndOverlay'
import logo from '../logos/Rachel-logo(revised3).svg'

const Header = styled.header``
const Wrapper = styled.div``
const Menu = styled.div``
const Logo = styled.div``

const NavBar = props => (
  <Header className={styles.container + 'pv3'}>
    <Wrapper className="flex justify-end justify-center-ns items-center relative">
      <Menu className="absolute left-0 flex items-center w-100 h-100 lh-solid">
        <MenuToggleAndOverlay {...props} />
      </Menu>
      <Logo className="pl1 pl0-ns w-85 w-75-ns mw30r lh-solid">
        <img src={logo} alt="Logo for Rachel Krehm, soprano" />
      </Logo>
    </Wrapper>
  </Header>
)

export default NavBar
