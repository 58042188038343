const Event = ({ item }) => (
  <ImageWrapper className="relative bg-gold h-100 tc tl-l">
    {item.image && (
      <Image
        fluid={item.image.file.childImageSharp.fluid}
        style={{ position: `absolute` }}
        imgStyle={{ objectPosition: item.image.objPosition }}
        className="top-0 left-0 w-100 h-100"
      />
    )}

    <Wrapper className="flex-l justify-between-l items-center-l relative top-0 left-0 bt b--black-10 bg-white pv4 ph3 w-100 animate hover-bg-black-60 hover-white">
      <div className="mb3 mb0-l w-20-l">
        <time className="db oswald f4 b ttu tracked">{item.date}</time>
        {item.date2 && (
          <time className="db oswald f4 b ttu tracked">{item.date2}</time>
        )}
        <div
          className={styles.divider + "center-not-l"}
          style={{ marginBottom: "1.5rem" }}
        />
        <h5 className="f4 fw3 silver">{item.company}</h5>
      </div>
      <h4 className="mb3 mb0-l ph4-l w-60-l open-sans f4 b lh-title">
        {item.title}
      </h4>
      <div className="w-20-l tr-l">
        <p className="mb3 oswald fw3 silver">{item.location}</p>
        {item.link && (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.buttonGold}
          >
            {item.type}
          </a>
        )}
      </div>
    </Wrapper>
  </ImageWrapper>
);

///////////////////////////////////////////////////////////////////////////////////

const ImageWrapper = styled.div`
  & > div.gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

// Hover styles for inner elements
const Wrapper = styled.div`
  &:hover > div > h5,
  &:hover > div > p {
    color: white !important;
  }
`;

///////////////////////////////////////////////////////////////////////////////////

import React from "react";
import Image from "gatsby-image";
import styled from "styled-components";

// import Img from '../Img'
import { styles } from "../../utils/theme";

export default Event;
