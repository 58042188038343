import React from 'react'

import FaFacebook from 'react-icons/lib/fa/facebook'
import FaTwitter from 'react-icons/lib/fa/twitter'
import FaInstagram from 'react-icons/lib/fa/instagram'
import FaYouTube from 'react-icons/lib/fa/youtube'

import { styles } from '../utils/theme'

// Social link data
const socials = [
	{
		icon: <FaFacebook className="f4" />,
		link: 'https://www.facebook.com/rachelkrehmsoprano'
	},
	{
		icon: <FaTwitter className="f4" />,
		link: 'https://twitter.com/RachelKrehm'
	},
	{
		icon: <FaInstagram className="f4" />,
		link: 'https://www.instagram.com/rachelkrehmsoprano/'
	},
	{
		icon: <FaYouTube className="f4" />,
		link: 'https://www.youtube.com/channel/UC9Jir6cQHCkEnpv-WMuV1Vw'
	}
]

const SocialItem = props => (
	<li className="dib">
		<a
			href={props.item.link}
			target="_blank"
			rel="nofollow"
			className={props.styles}>
			{props.item.icon}
		</a>
	</li>
)

const SocialLinks = props => (
	<ul className="ml0">
		{socials.map(item => {
			return (
				<SocialItem item={item} key={item.link} styles={props.linkStyles} />
			)
		})}
	</ul>
)

export default SocialLinks
