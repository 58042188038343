// import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'

/* 

INSTRUCTIONS:

1. Use like this:

import BgImage from './BgImage'

<BgImage
  height="50vh" (optional; default: auto)
  fit="cover" (optional; default: cover)
  position="50% 0%" (optional; default: 50% 50%)
  alt={this.props.title}
  title={this.props.title}
  fluid={this.props.fluid}
/>

2. Place inside a wrapping element with position: relative.
3. Add height property to the BgImage component (not the wrapping element)
4. Install object-fit-images polyfill like this:

npm install --save object-fit-images
import objectFitImages from 'object-fit-images'
objectFitImages() <-- where to put this...?

*/

// FOR ME:
const BgImage = styled(Image)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: -1;
	height: ${props => props.height || ''};

	/* Adjust image positioning (if image covers area with defined height) */
	& > img {
		object-fit: ${props => props.fit || 'cover'} !important;
		object-position: ${props => props.position || '50% 50%'} !important;
		font-family: 'object-fit: ${props =>
      props.fit || 'cover'} !important; object-position: ${props =>
  props.position || '50% 50%'} !important;'
	}
`

export default BgImage
