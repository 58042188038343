import React from "react";
import Image from "gatsby-image";

import { styles } from "../../utils/theme";

const Article = ({ article }) => (
  <li className="flex flex-column mb4 mb3-l mh2-l w-25-l">
    <div className="aspect-ratio aspect-ratio--8x5">
      <Image
        fluid={article.image.node.fluid}
        alt={article.altText}
        title={article.altText}
        style={{ position: `absolute` }}
        className="aspect-ratio-child top-0 left-0 right-0 bottom-0"
      />
    </div>
    <div className="bg-white pv4 ph3 h-100">
      <h3 className="mb3 f2 fw3 gold">{article.title}</h3>
      <p className="mb3">{article.blurb}</p>
      <a
        href={article.link}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.linkGold + "oswald f4 fw3"}
      >
        Read More
      </a>
    </div>
  </li>
);

export default Article;
