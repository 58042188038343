import React from 'react'
import shortid from 'shortid'

import CategorySliders from './CategorySliders'
import Filters from './Filters'
import { styles } from '../../utils/theme'

class SlidersContainer extends React.Component {
  // Pull in the updated data from the Media component
  state = {
    initialItems: [
      {
        id: shortid.generate(),
        category: 'onstage',
        checkedByDefault: true,
        slides: this.props.onstageSlidesWithImages
      },
      {
        id: shortid.generate(),
        category: 'offstage',
        checkedByDefault: false,
        slides: this.props.offstageSlidesWithImages
      },
      {
        id: shortid.generate(),
        category: 'video',
        checkedByDefault: false,
        slides: this.props.videoSlidesWithImages
      },
      {
        id: shortid.generate(),
        category: 'portrait',
        checkedByDefault: false,
        slides: this.props.portraitSlidesWithImages
      }
    ],
    items: []
  }

  componentWillMount = () => {
    this.setState({ items: this.state.initialItems })
    this.showOnstageSlider()
  }

  // Show the onstage slider on first load
  showOnstageSlider = () => {
    let updatedList = [...this.state.initialItems]
    updatedList = updatedList.filter(item => {
      return item.category == 'onstage'
    })
    this.setState({ items: updatedList })
  }

  // Show the slider that matches the checked filter
  filterMediaByCategory = event => {
    let updatedList = [...this.state.initialItems]
    updatedList = updatedList.filter(item => {
      return item.category == event.target.value
    })
    this.setState({ items: updatedList })
  }

  // Pass the filterMediaByCategory method to the Filters and the items state to the Sliders
  render() {
    return (
      <div className={styles.container}>
        <Filters
          items={this.state.initialItems}
          filterMediaByCategory={this.filterMediaByCategory}
        />

        <CategorySliders items={this.state.items} />
      </div>
    )
  }
}

export default SlidersContainer
