import React from 'react'

import styled from 'styled-components'
import { styles } from '../utils/theme'

// NOTE: code below adapted from this: https://github.com/imorente/gatsby-netlify-form-example/blob/master/src/pages/contact.js

// TODO: extract the inputs below into an Input component I can just pass props to (worth it?)...?

const Name = styled.input``
const Email = styled.input``
const Subject = styled.input``
const Message = styled.textarea``

// Create the URL encoding for the form submission
function encode(data) {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

class FormNetlify extends React.Component {
	state = {
		notSent: true,
		sentSuccessfully: false
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value })
	}

	handleSubmit = e => {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', ...this.state })
		})
			.then(() => this.setState({ notSent: false, sentSuccessfully: true }))
			.catch(error => alert(error))

		e.preventDefault()
	}

	render() {
		return (
			<div>
				{/* Show the form until it has been submitted successfully */}
				{this.state.notSent && (
					<form
						name="contact"
						method="post"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						onSubmit={this.handleSubmit}>
						<input name="bot-field" className="dn" />
						<div className="flex-ns justify-between-ns nl2-ns nr2-ns">
							<Name
								type="text"
								name="name"
								placeholder="Name"
								required
								onChange={this.handleChange}
								className={styles.input + 'mh2-ns w-50-ns'}
							/>

							{/* Includes validation for the domain portion of the email address */}
							<Email
								type="email"
								name="email"
								placeholder="Email"
								required
								title="The domain portion of the email address is invalid (the portion after the @)."
								pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
								onChange={this.handleChange}
								className={styles.input + 'mh2-ns w-50-ns'}
							/>
						</div>
						<Subject
							type="text"
							name="subject"
							placeholder="Subject"
							required
							onChange={this.handleChange}
							className={styles.input}
						/>
						<Message
							name="message"
							placeholder="Message"
							rows="7"
							required
							onChange={this.handleChange}
							className={styles.input}
						/>
						<div className="cf">
							<button
								type="submit"
								className={styles.buttonGoldtoGhost + ' fr'}>
								Send
							</button>
						</div>
					</form>
				)}

				{/* Hide form and show success message after form has submitted successfully */}
				{this.state.sentSuccessfully && (
					<div className="mt4 bg-gold pa3 tc white">
						Thanks for your message! I'll be in touch soon.
					</div>
				)}
			</div>
		)
	}
}

export default FormNetlify
