import React from "react";
import shortid from "shortid";
import opera5Logo from "../logos/logo-opera5.png";

// TODO: next time, include all markup and styling conditionally in the render function of the slide (only include raw data here, no presentation)

const slides = [
  {
    id: shortid.generate(),
    bgPos: "60% 100%",
    altText: "Portrait of Rachel Krehm by Hayley Andoff Photography",
    info: (
      <h3 className="flex items-end pr3 lh-solid f1 fw4 white">
        <div className="br bw2 b--white pr2 tc ttu">
          <div className="f1  ttu">3</div>
          <div className="mb1 f3">June</div>
        </div>
        <div className="pl2">
          <div className="mb1 f2 f1-ns">North York Concert Orchestra: Unforgettable & Unfinished</div>
          <div className="lh-title f3 fw3">
            Rachel sings Ryan Trew's "Come Closer: Songs on Texts by Elizabeth Krehm"
          </div>
        </div>
      </h3>
    )
  },
  {
    id: shortid.generate(),
    bgPos: "60% 100%",
    altText: "Portrait of Rachel Krehm by Hayley Andoff Photography",
    info: (
      <h3 className="flex items-end pr3 lh-solid f1 fw4 white">
        <div className="br bw2 b--white pr2 tc ttu">
          <div className="f1  ttu">10</div>
          <div className="mb1 f3">Nov</div>
        </div>
        <div className="pl2">
          <div className="mb1 f2 f1-ns">Thunder Bay Symphony Orchestra: A Night to Remember</div>
          <div className="lh-title f3 fw3">
            Rachel sings Ryan Trew's "Come Closer: Songs on Texts by Elizabeth Krehm"
          </div>
        </div>
      </h3>
    )
  },
  {
    id: shortid.generate(),
    bgPos: "0% 100%",
    altText: "Portrait of Rachel Krehm by Hayley Andoff Photography",
    info: (
      <h3 className="flex items-end pr3 lh-solid f1 fw4 white">
        <div className="br bw2 b--white pr2 tc ttu">
          <div className="f1  ttu">Now</div>
          <div className="mb1 f3">Online</div>
        </div>
        <div className="pl2">
          <div className="mb1 f2 f1-ns">Threepenny Submarine. </div>
          <div className="lh-title f3 fw3">
            Rachel wrote, produced, and performed in Threepenny Submarine
          </div>
          <div className="mt1 lh-title f5 f4-ns fw3">
            Watch Online
          </div>
        </div>
      </h3>
    )
  },
  {
    id: shortid.generate(),
    bgPos: "45% 50%",
    altText: "Portrait of Rachel Krehm by Hayley Andoff Photography",
    info: (
      <h3 className="tc fw4 ttu white">
        <div className="mb1 bb bw2 b--white pb1 f-4r f-5p4r-ns">23/24</div>
        <div className="f1 f-4r-ns">Season</div>
        <div className="pt1 f5 f-1p35r-ns">Soprano - Producer</div>
      </h3>
    )
  },
  // {
  //   id: shortid.generate(),
  //   bgPos: "65% 50%",
  //   altText: "Portrait of Rachel Krehm by Emily Ding Photography",
  //   info: (
  //     <h3 className="tc fw4 white">
  //       <div className="mb1 bb bw2 b--white pb1 f2 f1-ns">
  //         Onstage - Opera 5
  //       </div>
  //       <div className="mb1 f3 f2p1r-ns fw3">Rosalinda - Die Fledermaus</div>
  //     </h3>
  //   )
  // },
  {
    id: shortid.generate(),
    bgPos: "47% 50%",
    altText: "Portrait of Rachel Krehm by Hayley Andoff Photography",
    info: (
      <h3 className="tc fw4 white">
        <div className="mb1 bb bw2 b--white pb1">
          <a
            href="http://opera5.ca/"
            target="_blank"
            rel="noopener noreferrer"
            className="pointer"
          >
            <img src={opera5Logo} alt="Logo for Opera 5" className="w-100" />
          </a>
        </div>
        <div className="mb1 f4 f3-ns">General Director & Co-Founder</div>
        <div className="f5 f4-ns fw3">Opera that Makes Sense</div>
      </h3>
    )
  }
  // ,
  // {
  //   id: shortid.generate(),
  //   bgPos: "50% 50%",
  //   altText: "Portrait of Rachel Krehm by Lexie Mitchell Photography",
  //   info: (
  //     <h3 className="flex items-end lh-solid f1 fw4 white">
  //       <div className="br bw2 b--white pr2 tc ttu">
  //         <div className="f1 f-4r-ns ttu">08</div>
  //         <div className="mb1 f2">Nov</div>
  //       </div>
  //       <div className="pl2">
  //         <div className="mb1 f2 f1-ns">Eight Singers Drinking</div>
  //         <div className="lh-title f3 fw3">
  //           Rachel mixes drinks and sings with Tongue in Cheek Productions and
  //           Opera 5
  //         </div>
  //         <div className="mt1 f5 f4-ns fw3">Gallery 345, Toronto, ON</div>
  //       </div>
  //     </h3>
  //   )
  // }
];

export default slides;
