import React from 'react'

import { colors } from '../utils/theme'

const Bar = props => {
	const barLightOnLarge = props.lightOnLarge ? ' b--white-l ' : ' b--gold '
	return <div className={barLightOnLarge + 'bl bw2 dib ph2 h3 h2-ns'} />
}

const Heading = ({ main, sub, lightOnLarge }) => {
	const mainLightOnLarge = lightOnLarge ? ' white-l ' : ''
	const subLightOnLarge = lightOnLarge ? ' sand-l ' : ''

	return (
		<div className="flex items-end relative mb4 mb5-l">
			<Bar lightOnLarge={lightOnLarge} />
			<div>
				<h2 className={mainLightOnLarge + 'mb2 f-3p5r fw4'}>{main}</h2>
				<h6 className={subLightOnLarge + 'f3 fw3 gold'}>{sub}</h6>
			</div>
		</div>
	)
}

export default Heading
