import React from 'react'
import shortid from 'shortid'
import YouTube from 'react-youtube'

const videoOpts = {
  // height: '100%',
  // width: '569',
  playerVars: { autoplay: 1 }
}

const videoSlides = [
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="e2WaTCMDGWk" // Dich, teure Halle
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="VrGwEb4tpY4" // Es gibt ein Reich
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="833xO_Lf6eE" // Ukrainian Art Soirées
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="ePY5NJlPLjM" // Open Chambers/Shostakovich scene
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="WYx1f1rMnFw" // Arias
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="L5n8LonTFEo" // Fledermaus scene
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="m1SbQlyNjVA" // Opera 5 promo
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="u3DkBZ8pkio" // Opera Cheats: Le Nozze
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="sByZ_GjC7EY" // Opera Cheats: What the Fach?
        opts={videoOpts}
        className="absolute top-0 left-0 w-100 h-100"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="JtpnxdpB_Ww" // Willful interview
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="doolDfMrHBQ" // Whose Opera #1
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  },
  {
    id: shortid.generate(),
    hasImage: false,
    hasVideo: true,
    caption: '',
    video: (
      <YouTube
        videoId="Tdi_HkcPMBw" // Whose Opera #2
        opts={videoOpts}
        className="aspect-ratio--object"
      />
    )
  }
]

export default videoSlides
