import React from 'react'
import styled from 'styled-components'

import Headroom from 'react-headroom'
import FaBars from 'react-icons/lib/fa/bars'
import Scroll from 'react-scroll'
const ScrollLink = Scroll.Link

import logo from '../logos/Rachel-logo(revised3).svg'
import { styles } from '../utils/theme'

const StyledHeadroom = styled(Headroom)`
  /* Remove these styles if header is visible at top of page */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  /* Uncomment positioning styles (in all remaining classes) if header is visible at top of page */
  .headroom {
    /* top: 0;
    left: 0;
    right: 0;
    z-index: 1; */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, opacity 0s linear 0.3s;
  }

  /* Hide at top (remove opacity and set translateY(0%) to show at top) */
  .headroom--unfixed {
    /* position: relative; */
    opacity: 0;
    transform: translateY(-100%);
  }

  /* Remove opacity if shown at top */
  .headroom--unpinned {
    /* position: fixed; */
    opacity: 1;
    box-shadow: none;
    transform: translateY(-100%);
  }

  /* Remove opacity if shown at top */
  .headroom--pinned {
    /* position: fixed; */
    opacity: 1;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    transform: translateY(0%);
  }
`

const Header = styled.header``
const OuterWrapper = styled.div``
const InnerWrapper = styled.div``
const Menu = styled.div``
const Logo = styled.div``

// Render Header
const HeaderWithHeadroom = props => (
  <StyledHeadroom pinStart={800} disableInlineStyles>
    <Header className="bg-white">
      <OuterWrapper className={styles.container + 'pv2'}>
        <InnerWrapper className="flex justify-end justify-center-ns items-baseline items-center-ns relative">
          <Menu className="absolute left-0 flex items-center w-100 h-100 lh-solid">
            <FaBars
              onClick={props.showModal}
              className={styles.linkCoffee + 'db f3 lh-solid animate pointer'}
            />
          </Menu>
          <Logo className="pl1 pl0-ns w-85 w-60-ns mw5 lh-solid">
            <ScrollLink
              to="hero"
              smooth="easeInOutQuart"
              duration={1750}
              className={styles.linkSand + 'flex oswald f2'}
            >
              <img src={logo} alt="Logo for Rachel Krehm, soprano" className="w-100" />
            </ScrollLink>
          </Logo>
        </InnerWrapper>
      </OuterWrapper>
    </Header>
  </StyledHeadroom>
)

export default HeaderWithHeadroom
