import React from 'react'
import Slick from 'react-slick'

import styled from 'styled-components'
import { colors } from '../../utils/theme'

import Angle from '../Angle'
import Review from './Review'
import reviews from '../../data/reviews'

// Custom styles for [react-slick]
const StyledSlick = styled(Slick)`
	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		display: flex !important;
		height: auto !important;
	}
`

const Reviews = () => {
	const settings = {
		arrows: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		fade: true,
		speed: 2000,
		draggable: false,
		slidesToShow: 1,
		slidesToScroll: 1
	}
	return (
		<div id="reviews" className="relative">
			<Angle top right color={colors.light} />
			<StyledSlick {...settings}>
				{reviews.map(review => {
					return <Review key={review.id} review={review} />
				})}
			</StyledSlick>
		</div>
	)
}

export default Reviews
