import React from 'react'
import shortid from 'shortid'

const paragraphs = [
	{
		id: shortid.generate(),
		text: (
			<span>
				A multi-faceted artist, soprano Rachel Krehm is the co-founder and General Director of Opera 5. As a performer she works in opera, new music, art song, improvisation, and voice and web-series acting. Along with performing, she is a writer and producer. In 2019, Rachel performed the title role of Strauss’ <em>Ariadne auf Naxos</em> with Highlands Opera Studio where her performance was described as “soulful” by Opera Canada. She has performed Poulenc’s <em>La voix humaine</em> multiple times, most recently with Toronto City Opera, where her performance was described as “a breathtaking virtuoso performance of boundless passion and seemingly limitless musicality” by Opera Going Toronto.

			</span>
		)
	},
	{
		id: shortid.generate(),
		text: (
			<span>
				She is a regularly featured actor in the popular web-series, Opera Cheats. Rachel is also a founding member of Canada’s only opera improv team <em>Whose Opera is it Anyway</em> which performed monthly improvised sketch comedy featuring operatic singing at comedy clubs in Toronto and festivals around Ontario. Rachel is an avid contributor to the independent opera scene in Toronto. Aside from co-founding and running Opera 5, she sits on the Executive committee of Indie Opera T.O., the association of independent opera companies in Toronto.
			</span>
		)
	},
	{
		id: shortid.generate(),
		text: (
			<span>
				An avid believer in new creation, Rachel works in commissioning, workshopping, and creating Canadian art song and opera. In 2018, she premiered an orchestral song cycle by Vancouver based composer Ryan Trew on texts by her late sister Elizabeth Krehm called <em>Come Closer</em>. Rachel has now created a libretto to expand this work into a chamber opera with Ryan Trew. The opera explores the relationship of sisters complicated by drug addiction and separated by death and include writing by both of the sisters weaved together through memories, dreams, and conversations. <em>Come Closer</em> saw development workshops with Pacific Opera Victoria’ Artist Residency program in June 2022 and Opera McGill’s Beta Lab in the spring of 2022. The commission is generously supported by the Canada Council for the Arts.
			</span>
		)
	},
	{
		id: shortid.generate(),
		text: (
			<span>
				Through the pandemic, Rachel co-wrote, produced, and performed in the <em>Threepenny Submarine</em> pilot, which is Opera 5’s new web series that follows puppets Lydian and Iona on deep sea adventures and combines puppetry and opera in collaboration with Gazelle Automations. Supported by the Canada Council for the Arts, eight more episodes will be created and produced in 2022. In the fall of 2021 Rachel sang Trew’s <em>Come Closer</em> with the Kingston Symphony Trew’s and then she will perform selections from Anna Pidgorna’s new opera <em>Plaything</em> with Société de musique contemporaine du Québec and Musique 3 Femmes. In the fall of 2022, Rachel traveled to Berlin for the world premiere of the completed <em>Plaything</em> with Musique 3 Femmes and ufaFabrik. Rachel will sing <em>Come Closer</em> with North York Concert Orchestra in Toronto in June 2023 and with the Thunder Bay Symphony in November 2023.
			</span>
		)
	}
]

export default paragraphs
