import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import 'slick-carousel/slick/slick.css'

import HeaderWithHeadroom from '../components/HeaderWithHeadroom'
import MenuOverlay from '../components/MenuOverlay'
import Footer from '../components/Footer'
// import { styles } from '../utils/theme'
// import logo from '../logos/Rachel-logo(revised3).svg'
// import logoChrome from '../logos/logo-chrome.svg'
// import logoFirefox from '../logos/logo-firefox.svg'
// import logoEdge from '../logos/logo-edge.svg'
// import logoOpera from '../logos/logo-opera.svg'

// Import open-source fonts from Typefaces (includes @font-face automatically)
import 'typeface-oswald'
import 'typeface-open-sans'

// if (
//   process.env.NODE_ENV === `development` ||
//   (process.env.NODE_ENV === `production` && process.browser !== true)
// ) {
//   require('../utils/tachyons-custom/tachyons.css')
//   // require('../../node_modules/slick-carousel/slick/slick.css')
// }
import '../utils/tachyons-custom/tachyons.css'
import GlobalStyles from '../utils/globalStyles'

// const browsers = [
//   {
//     name: 'Chrome',
//     logo: logoChrome,
//     link: 'https://www.google.ca/chrome/browser/'
//   },
//   {
//     name: 'Edge',
//     logo: logoEdge,
//     link: 'https://www.microsoft.com/en-ca/windows/microsoft-edge'
//   },
//   {
//     name: 'Firefox',
//     logo: logoFirefox,
//     link: 'https://www.mozilla.org/en-US/firefox/'
//   },
//   { name: 'Opera', logo: logoOpera, link: 'http://www.opera.com/' }
// ]

function Base({ children }) {
  const [modalVisible, setModalVisible] = React.useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }

        menuBg: imageSharp(fluid: { originalName: { regex: "/menu-bg.jpg/" } }) {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }

        menuLogo: imageSharp(
          fluid: { originalName: { regex: "/menu-logo.png/" } }
        ) {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }

        footer: imageSharp(fluid: { originalName: { regex: "/footer/" } }) {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `
  )

  return (
    <div className="min-vh-100 flex flex-column">
      <Helmet>
        {/* <!-- COMMON TAGS --> */}
        <meta charset="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
        {/* <!-- Search Engine --> */}
        <meta
          name="description"
          content="Canadian soprano Rachel Krehm is an opera, theatre and comedy performer, the co-founder and General Director of Opera 5, and an Executive Committee member of Indie Opera T.O."
        />
        {/* <!-- Schema.org for Google --> */}
        <meta itemprop="name" content={data.site.siteMetadata.title} />
        <meta
          itemprop="description"
          content="Canadian soprano Rachel Krehm is an opera, theatre and comedy performer, the co-founder and General Director of Opera 5, and an Executive Committee member of Indie Opera T.O."
        />
        {/* <!-- Twitter --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta
          name="twitter:description"
          content="Canadian soprano Rachel Krehm is an opera, theatre and comedy performer, the co-founder and General Director of Opera 5, and an Executive Committee member of Indie Opera T.O."
        />
        <meta name="twitter:site" content="@RachelKrehm" />
        {/* <!-- Open Graph general (Facebook, Pinterest & Google+) --> */}
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta
          property="og:description"
          content="Canadian soprano Rachel Krehm is an opera, theatre and comedy performer, the co-founder and General Director of Opera 5, and an Executive Committee member of Indie Opera T.O."
        />
        <meta property="og:url" content="https://www.rachelkrehm.com" />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:locale" content="en_CA" />
        <meta property="fb:admins" content="752791894922209" />
        <meta property="og:type" content="website" />
      </Helmet>

      <GlobalStyles />

      <div>
        <HeaderWithHeadroom
          menuBg={data.menuBg.fluid}
          menuLogo={data.menuLogo.fluid}
          showModal={() => setModalVisible(true)}
          hideModal={() => setModalVisible(false)}
        />

        {children}

        <Footer fluid={data.footer.fluid} alt="Logo for Rachel Krehm, Soprano" />

        <Rodal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          closeOnEsc={true}
          showCloseButton={false}
          showMask={false}
          animation="fade"
          duration={500}
          customStyles={{ padding: 0, width: '100vw', height: '100vh' }}
        >
          <MenuOverlay
            menuBg={data.menuBg.fluid}
            menuLogo={data.menuLogo.fluid}
            showModal={() => setModalVisible(true)}
            hideModal={() => setModalVisible(false)}
          />
        </Rodal>
      </div>
    </div>
  )
}

export default Base
