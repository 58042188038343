// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

// TODO: if not using, consider deleting data/events.yml as well

function useCalendarData() {
  const { allCalendarYaml } = useStaticQuery(
    graphql`
      query {
        allCalendarYaml {
          edges {
            node {
              season
              date
              company
              title
              location
              type
              link
              image {
                file {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                objPosition
              }
            }
          }
        }
      }
    `
  )

  return allCalendarYaml.edges
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default useCalendarData

/*

import useCalendarData from '../data/useCalendarData'

const events = useCalendarData()

*/
