import shortid from "shortid";


const articles = [
  {
    id: shortid.generate(),
    title: "Article: Healing through Song",
    blurb:
      "Rachel talks to the Read magazine about her grief process after losing her sister Elizabeth and how her work in opera is healing.",
    link: 'https://www.rachelkrehm.com/Read-article.pdf',
    altText: "Lauren Margison in Suor Angelica, dressed as a nun and praying."
  },
  {
    id: shortid.generate(),
    title: "Review: The Telephone & La Voix Humaine",
    blurb:
      "Two telltale modern one-act operas, one born in the razzle dazzle of mid-20th century New York, the other in the lingering disillusionment and artistic rebellion of post World War I Paris.",
    link: "https://operagoto.com/review-the-telephone-la-voix-humaine/?fbclid=IwAR18vC5eijYoVLBsASyd8QCAoVEaqnRLkVzUlOpdmKlXI2bwFCmqhrteSyk",
    altText: "Lauren Margison in Suor Angelica, dressed as a nun and praying."
  },
  {
    id: shortid.generate(),
    title: "Review: Tales of Gods and Social Media",
    blurb:
      'Highlands Opera Studio Review: "Rachel Krehm’s facial expressions as Ariadne were priceless, and her soulful “Ach! Wo war ich?” demonstrated the richness of Strauss’ writing for the female voice."',
    link: "http://operacanada.ca/review-highlands-opera-studio/",
    altText: "Lauren Margison in Suor Angelica, dressed as a nun and praying."
  },
  {
    id: shortid.generate(),
    title: "Seventh Annual Memorial Concert",
    blurb:
      "Each year since her passing, the Krehm family has held a memorial concert as a fundraiser for St. Michael's ICU, which has now raised over $100,000 for the hospital.",
    link: "http://smh.convio.net/site/TR?pxfid=3998&pg=fund&fr_id=1100",
    altText: "Portrait of Rachel Krehm by Hayley Photography."
  },
  {
    id: shortid.generate(),
    title: "Toronto Opera Company Ditches Tuxes & Gowns",
    blurb:
      '"Opera 5’s past productions have incorporated elements from the worlds of photography, dance, cabaret/pop culture..."',
    link:
      "https://www.thestar.com/entertainment/stage/2016/07/05/toronto-opera-company-ditches-the-gowns-and-tuxes.html",
    altText: "Rachel Krehm as a French mime."
  }
];

export default articles;
