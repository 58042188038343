import React from 'react'

import FlipMove from 'react-flip-move'

import CategorySlider from './CategorySlider'

// Generate four sliders (one per category) with different slides in each
const CategorySliders = ({ items }) => (
	<FlipMove duration="300">
		{items.map(item => {
			return (
				<div key={item.id}>
					<CategorySlider items={item.slides} category={item.category} />
				</div>
			)
		})}
	</FlipMove>
)

export default CategorySliders
