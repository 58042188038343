function Calendar() {
  const events = useCalendarData()

  return (
    <section id="calendar" className={styles.section + 'relative'}>
      <div className={styles.container}>
        <Heading main="Calendar &amp; Upcoming" sub="Opera | Concert | Opera 5" />
        <EventsBySeason events={events} />
        <Angle bottom right color={colors.light} />
      </div>
    </section>
  )
}

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'

import Angle from '../Angle'
import EventsBySeason from './EventsBySeason'
import Heading from '../Heading'
import useCalendarData from '../../data/useCalendarData'
import { colors, styles } from '../../utils/theme'

export default Calendar
