import React from 'react'
import Image from 'gatsby-image'

import { styles } from '../utils/theme'

const Footer = ({ fluid, alt }) => (
  <footer className="bg-coffee pv3 w-100 tc white">
    <div className={styles.container}>
      <div className="center w-85 mw5">
        <Image fluid={fluid} alt={alt} />
      </div>
      <p className="pt2 f6">
        &copy; {new Date().getFullYear()} Rachel Krehm. All rights reserved.
      </p>
      <p className="f7">
        Built by{' '}
        <a
          href="http://www.coffeeshopcreative.ca"
          target="_blank"
          rel="nofollow"
          className={styles.linkWhite}
        >
          Coffeeshop Creative
        </a>
        .
      </p>
    </div>
  </footer>
)

export default Footer
