import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import AnimateHeight from 'react-animate-height'

import { media, colors, styles } from '../utils/theme'

import Angle from './Angle'
import BgImage from './BgImage'
import Heading from './Heading'
import paragraphs from '../data/bio'

const ImageWrapper = styled.div`
  position: relative;

  /* Make background image height adjust to overlay content height */
  /* TODO: add this to Playground & Github issue */
  @media screen and (${media.lg}) {
    .gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
`

class Bio extends React.Component {
  state = {
    height: 'auto',
    buttonText: 'Read More',
    buttonVisible: false,
    bgImage: this.props.fluid[0].node.fluid
  }

  // Detect window width
  componentDidMount = () => {
    this.updateWindowWidth()
    window.addEventListener('resize', this.updateWindowWidth)
  }

  // On large screens, expand content and hide "read more" button
  updateWindowWidth = () => {
    this.setState({
      height: window.innerWidth < 960 ? 0 : 'auto',
      buttonVisible: window.innerWidth < 960 ? true : false,
      bgImage:
        window.innerWidth < 480
          ? this.props.fluid[0].node.fluid
          : this.props.fluid[1].node.fluid
    })
  }

  // Toggle expanded state and button text
  toggleHeight = () => {
    if (this.state.height === 0) {
      this.setState({ height: 'auto', buttonText: 'Read Less' })
    } else {
      this.setState({ height: 0, buttonText: 'Read More' })
    }
  }

  // Remove resize event listener
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowWidth)
  }

  render() {
    return (
      <ImageWrapper id="bio">
        <Angle top right color="white" />
        <BgImage
          // position="0% 50%"
          fluid={this.state.bgImage}
          // height="100%" // TODO: add this to Playground & Github issue
          imgStyle={{ height: `100%`, objectPosition: `0% 50%` }}
          alt="Portrait of Rachel Krehm by Hayley Andoff Photography"
          title="Portrait of Rachel Krehm by Hayley Andoff Photography"
        />
        <div className="dn-l relative">
          <Angle bottom left color={colors.light} />
        </div>
        <div className="relative-l top-0-l left-0-l z-1 w-100 bg-black-20-l white-l">
          <div
            className={
              styles.section +
              styles.container +
              'flex-l flex-column-l items-end-l bg-light-not-l'
            }
          >
            <Heading
              lightOnLarge
              main="Meet Rachel"
              sub="Singer, Writer, Producer"
            />

            <div className="columns-2-l w-70-l">
              {/* Display first paragraph */}
              <p className="mb3">{paragraphs[0].text}</p>

              <AnimateHeight duration={500} height={this.state.height}>
                {paragraphs.map((paragraph, index) => {
                  // Display inner paragraphs (not first or last)
                  if (index > 0 && index < paragraphs.length - 1) {
                    return (
                      <p key={paragraph.id} className="mb3">
                        {paragraph.text}
                      </p>
                    )
                    // Display last paragraph
                  } else if (index === paragraphs.length - 1) {
                    return (
                      <p key={paragraph.id} className="mb3 mb0-l">
                        {paragraph.text}
                      </p>
                    )
                  }
                })}
              </AnimateHeight>

              {this.state.buttonVisible && (
                <div className="tc">
                  <button
                    onClick={this.toggleHeight}
                    className={styles.buttonGold}
                  >
                    {this.state.buttonText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Angle bottom left color="white" classes="dn db-l" />
      </ImageWrapper>
    )
  }
}

export default Bio
