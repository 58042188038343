import { createGlobalStyle } from 'styled-components'
import { colors } from './theme'
// import CustomFontWoff2 from '../fonts/custom-font.woff2'
// import CustomFontWoff from '../fonts/custom-font.woff'

// Get all custom properties declared on :root element (causes a Gatsby build error since it's before componentDidMount() )
// See: http://vanseodesign.com/css/custom-properties-and-javascript/
// const styles = getComputedStyle(document.documentElement)

// Get a specific custom property value
// const pink = styles.getPropertyValue('--pink')
// console.log('pink: ' + pink)

const GlobalStyles = createGlobalStyle`
/*

UTILITY CLASSES (feel free to add !important where needed)

1. Dimensions
2. Typography
3. Colours
4. Effects

*/


/* POSITION */

.top-50 { top: 50%; }
.left-50 { left: 50%; }
.transXY--50 { transform: translate(-50%, -50%); }
.transY--50 { transform: translateY(-50%); }

.rotate-down-from-right {
  transform: rotate(-.2deg);
  transform-origin: right bottom;
}

.rotate-down-from-left {
  transform: rotate(.2deg);
  transform-origin: left bottom;
}

.rotate-up-from-right {
  transform: rotate(.2deg);
  transform-origin: right top;
}

.rotate-up-from-left {
  transform: rotate(-.2deg);
  transform-origin: left top;
}



/*
 1. DIMENSIONS (generate classes here: https://tachyons-tldr.now.sh/#/tools) 
 *************/


@media (max-width: 60em) { 
  .center-not-l { 
    margin-left: auto;
    margin-right: auto 
  } 
}

@media (min-width: 30em) { .w-75-ns { width: 75%; } }

.w-85 { width: 85%; }

.mw30r { max-width: 30rem; }

${
  '' /* .max-height-60em { max-height: 50em; }

@media (max-width: 60em) { .vh-75-not-l { height: 75vh; } } */
}


/*
 2. TYPOGRAPHY 
 *************/
.oswald {	font-family: Oswald, serif; }
.open-sans { font-family: 'Open Sans', sans-serif; }

@media (min-width: 30em) { .f-1p35r-ns { font-size: 1.35rem !important; } }

@media (min-width: 30em) { .f2p1r-ns { font-size: 2.1rem !important; } }

.f-3p5r { font-size: 3.5rem !important; }

.f-4r { font-size: 4rem !important; }
@media (min-width: 30em) { .f-4r-ns { font-size: 4rem !important; } }

@media (min-width: 30em) { .f-5p4r-ns { font-size: 5.4rem !important; } }

@media (min-width: 60em) {
	.columns-2-l {
		column-count: 2;
		column-width: calc(50% - 1rem);
		column-gap: 2rem;
	}
}


/*
 3. COLOURS (generate classes here: https://tachyons-tldr.now.sh/#/tools) 
 **********/
.coffee { color: ${colors.coffee}; }
.bg-coffee { background-color: ${colors.coffee}; }
.b--coffee { border-color: ${colors.coffee}; }
.hover-coffee:hover,
.hover-coffee:focus { color: ${colors.coffee}; }
.hover-bg-coffee:hover,
.hover-bg-coffee:focus { background-color: ${colors.coffee}; }

.hover-b--gold:focus,
.hover-b--gold:hover { border-color: ${colors.gold}; }

.sand { color: ${colors.sand}; }
.bg-sand { background-color: ${colors.sand}; }
.b--sand { border-color: ${colors.sand}; }
.hover-sand:hover, 
.hover-sand:focus { color: ${colors.sand}; }
.hover-bg-sand:hover, 
.hover-bg-sand:focus { background-color: ${colors.sand}; }
@media (min-width: 60em) { .sand-l { color: ${colors.sand} !important; } }

@media (max-width: 60em) { .bg-light-not-l { background-color: ${colors.light}; } }
@media (min-width: 60em) { .bg-black-20-l { background-color: rgba(0,0,0,.2) } }

.hover-b--white:focus,
.hover-b--white:hover { border-color: white; }
@media (min-width: 60em) { .white-l { color: white; } }
@media (min-width: 60em) { .b--white-l { border-color: white; } }


/*
 4. EFFECTS 
 **********/
.animate { transition: all 0.15s ease-in-out; }

.touch-scroll { -webkit-overflow-scrolling: touch; }


/* 

Override Firefox's premature red box-shadow styling for invalid form inputs

- see: https://stackoverflow.com/questions/5939341/firefox-4-is-there-a-way-to-remove-the-red-border-in-a-required-form-input 
- see: https://stackoverflow.com/questions/3809146/firefox-4-required-input-form-red-border-outline

*/
input:-moz-placeholder,
input:focus,
input:invalid { box-shadow: none !important; }

textarea:-moz-placeholder,
textarea:focus,
textarea:invalid { box-shadow: none !important; }

/*

FONT DECLARATIONS

1. Custom font name

*/

${
  '' /* @font-face {
  font-family: "Custom Font";
  font-style: normal;
  font-weight: normal;
  src: local("Custom Font"), local("Custom Font Alias"), url(${CustomFontWoff2}) format("woff2"), url(${CustomFontWoff}) format("woff");
} */
}

`

export default GlobalStyles
