import React from 'react'

// TODO: make the angle of the triangle adjustable (large & small props)?
// TODO: add four more variants for upright along the sides (just add a "vertical" prop and then calculate the 4 variants)

const Angle = props => {
	let position = ''
	let points = ''

	// Customize the triangle points based on the position and size props (Y values measure down, not up)
	if (props.bottom && props.left) {
		points = '0,0 0,100 100,100'
		position = ' bottom-0 left-0 rotate-down-from-right '
	} else if (props.bottom && props.right) {
		points = '0,100 100,0 100,100'
		position = ' bottom-0 right-0 rotate-down-from-left '
	} else if (props.top && props.left) {
		points = '0,0 0,100 100,0'
		position = ' top-0 left-0 rotate-up-from-right '
	} else if (props.top && props.right) {
		points = '0,0 100,100 100,0'
		position = ' top-0 right-0 rotate-up-from-left '
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			className={position + props.classes + ' absolute z-2 w-100 h1 h2-l'}>
			<polygon fill={props.color} points={points} />
		</svg>
	)
}

export default Angle
