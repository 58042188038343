import React from 'react'

import { styles } from '../../utils/theme'

import Article from './Article'
import Heading from '../Heading'
import articles from '../../data/news'

const News = ({ fluid }) => {
  // Make a new copy of the articles array (so I can add images)
  const articlesWithImages = [...articles]

  // Add images from the newsImages query to articlesWithImages
  articlesWithImages.forEach((item, index) => {
    item.image = fluid[index]
  })

  return (
    <section id="news" className={styles.section + ' bg-near-white'}>
      <div className={styles.container}>
        <Heading
          main="Artist News"
          sub="Articles, news &amp; stories featuring soprano Rachel Krehm"
        />

        <ul className="flex-l justify-center-l ml0 nb4 pb4-l nl2-l nr2-l list flex-wrap">
          {articlesWithImages.map(item => {
            return <Article article={item} key={item.id} />
          })}
        </ul>
      </div>
    </section>
  )
}

export default News
