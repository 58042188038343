import React from 'react'
import styled from 'styled-components'

import { colors, styles } from '../../utils/theme'

// const FilterList = styled.ul``
// const FilterItem = styled.li``
// const Label = Button.withComponent('label')
// const FilterLabel = Label.extend``

// Checked input label styles
const FilterInput = styled.input`
	&:checked + label {
		background-color: ${colors.gold};
		color: white;
		transform: scale(1.1);
	}
`

// Filters that determine which slider is showing
const Filters = ({ items, filterMediaByCategory }) => (
	<ul className="flex flex-column flex-row-l items-center justify-end-l nt2 mb4 ml0 nl3-l pt2-l list">
		{items.map((item, index) => {
			return (
				<li key={item.category} className="db mt2 mb0-l ml3-l w4">
					<FilterInput
						onClick={filterMediaByCategory}
						id={item.category}
						type="radio"
						value={item.category}
						name="media"
						defaultChecked={item.checkedByDefault}
						style={{ display: 'none' }}
					/>
					<label
						htmlFor={item.category}
						className={styles.buttonGhost + ' w-100'}>
						{item.category}
					</label>
				</li>
			)
		})}
	</ul>
)

export default Filters
