import { css } from 'styled-components'

/*

INSTRUCTIONS

1. Call colours like this: background-color: ${colors.blue}
2. Call media queries like this: ${media.lg`display:flex;`} ...OR... ${media.lg(css`display:flex;`)}

*/

// Color Palette
export const colors = {
	coffee: '#765c3b', // --dark-red
	gold: '#ac936d', // --gold
	sand: '#f3eadb',
	light: '#f5f2ed', // --near-white
	darkText: '#817c73', // --gray
	lightText: '#cdcac7' // --moon-gray
}

// Media query strings
export const media = {
	ns: 'min-width: 30em',
	md: '(min-width: 30em) and (max-width: 59em)',
	lg: 'min-width: 60em',
	xl: 'min-width: 80em'
}

// Breakpoints (adjust these)
// const breakpoints = {
// 	sm: 480,
// 	md: 768,
// 	lg: 960,
// 	xl: 1168
// }

// // Media Queries (don't change this)
// export const media = Object.keys(breakpoints).reduce((acc, label) => {
// 	acc[label] = (...args) => css`
// 		@media (min-width: ${breakpoints[label] / 16}em) {
// 			${css(...args)};
// 		}
// 	`
// 	return acc
// }, {})

// Component styles (leave spaces around each string so additional styles can be added)
export const styles = {
	button:
		' dib outline-0 ba bw1 pv1 ph3 tc oswald ttu tracked animate pointer ',
	get buttonGhost() {
		return (
			this.button + ' b--gold bg-transparent gold hover-bg-gold hover-white '
		)
	},
	get buttonGold() {
		return (
			this.button +
			' b--gold bg-gold white hover-b--white hover-bg-white hover-gold '
		)
	},
	get buttonGoldtoGhost() {
		return this.button + ' b--gold bg-gold white hover-bg-white hover-gold '
	},
	get buttonWhite() {
		return this.button + ' b--white bg-white gold hover-bg-gold hover-white '
	},
	calendarLabel: ' dib pv1 f3 moon-gray animate pointer hover-gray ',
	container: ' center ph2 ph3-ns ph4-l mw9 ',
	divider: ' db bb bw2 b--gold pt2 w2 ',
	input:
		' db mb3 outline-0 ba bw1 b--near-white bg-near-white pv1 ph2 w-100 animate hover-b--gold ',
	linkWhite: ' white hover-gold pointer ',
	linkCoffee: ' coffee hover-black-80 pointer ',
	linkGold: ' gold hover-black-80 pointer ',
	linkSand: ' sand hover-white pointer ',
	section: ' pv5 '
}
