const Filters = ({ setSeason }) => (
  <ul className="ml0 mb3 mb4-l tc tl-l list">
    <li key="2023" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2023')}
        id="2023"
        type="radio"
        value="2023"
        name="season"
        defaultChecked
        className="dn"
      />
      <label htmlFor="2023" className={styles.calendarLabel}>
        2023
      </label>
    </li>
    <li key="2022" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2022')}
        id="2022"
        type="radio"
        value="2022"
        name="season"
        className="dn"
      />
      <label htmlFor="2022" className={styles.calendarLabel}>
        2022
      </label>
    </li>
    <li key="2021" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2021')}
        id="2021"
        type="radio"
        value="2021"
        name="season"
        className="dn"
      />
      <label htmlFor="2021" className={styles.calendarLabel}>
        2021
      </label>
    </li>

    <li key="2020" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2020')}
        id="2020"
        type="radio"
        value="2020"
        name="season"
        className="dn"
      />
      <label htmlFor="2020" className={styles.calendarLabel}>
        2020
      </label>
    </li>

    <li key="2019" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2019')}
        id="2019"
        type="radio"
        value="2019"
        name="season"
        className="dn"
      />
      <label htmlFor="2019" className={styles.calendarLabel}>
        2019
      </label>
    </li>

    <li key="2018" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2018')}
        id="2018"
        type="radio"
        value="2018"
        name="season"
        className="dn"
      />
      <label htmlFor="2018" className={styles.calendarLabel}>
        2018
      </label>
    </li>

    <li key="2017" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2017')}
        id="2017"
        type="radio"
        value="2017"
        name="season"
        className="dn"
      />
      <label htmlFor="2017" className={styles.calendarLabel}>
        2017
      </label>
    </li>

    <li key="2016" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2016')}
        id="2016"
        type="radio"
        value="2016"
        name="season"
        className="dn"
      />
      <label htmlFor="2016" className={styles.calendarLabel}>
        2016
      </label>
    </li>

    {/* <li key="2015" className="dib db-l ph2 ph0-l">
      <FilterInput
        onClick={() => setSeason('2015')}
        id="2015"
        type="radio"
        value="2015"
        name="season"
        className="dn"
      />
      <label htmlFor="2015" className={styles.calendarLabel}>
        2015
      </label>
    </li> */}
  </ul>
)

///////////////////////////////////////////////////////////////////////////////////

// Checked input label styles
const FilterInput = styled.input`
  &:checked + label {
    font-weight: bold;
    color: ${colors.darkText};
    transform: scale(1.1);
  }
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import { colors, styles } from '../../utils/theme'

export default Filters
