import React from 'react'
import shortid from 'shortid'

const reviews = [
	{
		id: shortid.generate(),
		quotation: `For those who admire what opera singers can do, 'Whose Opera is it
				Anyway?' is totally mind-blowing; these singers add serious variables to
				the existing pressure of singing in public and being appropriately funny
				in a comedy bar.`,
		source: `Jenna Douglas, Schmopera`
	},
	{
		id: shortid.generate(),
		quotation: `Our leading couple, Rosalinda and Eisenstein, were given fresh life in Rachel Krehm and Michael Barrett. Krehm's full ringing soprano filled the hall and suited the Viennese style quite well.`,
		source: `Gregory Finney, Schmopera`
	},
	{
		id: shortid.generate(),
		quotation: (
			<span>
				Rachel Krehm as Rosalinda enjoyed the comical oversize costume elements
				that were part of the Brechtian design scheme by Matthew Vaile. We’re
				put into a self-conscious, self-reflexive sort of world, at times
				resembling cabaret, yet the singing was full-out operatic, Krehm rising
				every time to the powerful high notes without losing her
				sense&nbsp;of&nbsp;fun.
			</span>
		),
		source: `Leslie Barcza, the barczablog`
	},
	{
		id: shortid.generate(),
		quotation: `A very difficult role for a singer and actress/singer Mme Krehm did it beautifully... This is live performance at its artistic best.`,
		source: `Alvina Ruprecht, Capital Critics’ Circle`
	},
	{
		id: shortid.generate(),
		quotation: `It’s just a beautiful show, with moments of dark humour interspersing one long descent into madness that gets sold thanks to Rachel Krehm’s terrific performance and utterly amazing voice. Seriously, she’ll rattle yer bones with her high notes, and that’s no foolin’. People should be flocking to this show because you really don’t get opera of this caliber for this price tag, like, EVER.`,
		source: `Kevin Reid, Visitorium`
	}
]

export default reviews
