import shortid from 'shortid'

const offstageSlides = [
	{
		id: shortid.generate(),
		hasImage: true,
		imagePos: '50% 50%',
		altText: 'Image by Lexie Mitchell Photography'
	},
	{
		id: shortid.generate(),
		hasImage: true,
		imagePos: '50% 50%',
		altText: 'Image by Lexie Mitchell Photography'
	},
	{
		id: shortid.generate(),
		hasImage: true,
		imagePos: '50% 50%',
		altText: 'Image by Lexie Mitchell Photography'
	},
	{
		id: shortid.generate(),
		hasImage: true,
		imagePos: '50% 50%',
		altText: 'Image by Emily Ding Photography'
	},
	{
		id: shortid.generate(),
		hasImage: true,
		imagePos: '50% 50%',
		altText: 'Image by Emily Ding Photography'
	},
	{
		id: shortid.generate(),
		hasImage: true,
		imagePos: '50% 50%',
		altText: 'Image by Emily Ding Photography'
	}
]

export default offstageSlides
