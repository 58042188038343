import React from 'react'
import FaBars from 'react-icons/lib/fa/bars'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import Scroll from 'react-scroll'
const ScrollLink = Scroll.Link

import styled from 'styled-components'
import { styles } from '../utils/theme'

import BgImage from './BgImage'
import MenuOverlay from '../components/MenuOverlay'
import SocialLinks from './SocialLinks'

// Link data
const scrollLinks = [
  { id: 'news', text: 'Artist News' },
  { id: 'bio', text: 'About Rachel' },
  { id: 'calendar', text: 'Calendar & Upcoming' },
  { id: 'media', text: 'Media & Image' },
  { id: 'contact', text: 'Contact Rachel' }
]

const Wrapper = styled.div`
  /* Make background image height adjust to overlay content height */
  /* TODO: add this to Playground & Github issue */
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

class MenuToggleAndOverlay extends React.Component {
  state = { visible: false }

  showModal = () => this.setState({ visible: true })
  hideModal = () => this.setState({ visible: false })

  render() {
    const modalStyles = { padding: 0, width: '100vw', height: '100vh' }

    return (
      <div>
        <FaBars
          onClick={this.showModal}
          className={styles.linkCoffee + 'f3 lh-solid animate pointer'}
        />
        <Rodal
          visible={this.state.visible}
          onClose={this.hideModal}
          closeOnEsc={true}
          showCloseButton={false}
          showMask={false}
          animation="fade"
          duration={500}
          customStyles={modalStyles}
        >
          <MenuOverlay
            menuBg={this.props.menuBg}
            menuLogo={this.props.menuLogo}
            showModal={this.showModal}
            hideModal={this.hideModal}
          />
        </Rodal>
      </div>
    )
  }
}

export default MenuToggleAndOverlay
