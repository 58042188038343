import React from 'react'
import Slick from 'react-slick'
import styled from 'styled-components'

import Slide from './Slide'
import slides from '../../data/hero'

// Custom styles for [react-slick]
const StyledSlick = styled(Slick)`
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    display: flex !important;
    height: auto !important;
  }

  .slick-dots {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 1rem 0.7rem 0;
    text-align: center;
    list-style: none;
  }

  @media screen and (min-width: 30em) {
    .slick-dots {
      margin: 0 1.5rem 2.25rem 0;
    }
  }

  .slick-dots > li {
    /* display: inline-block; */
    margin-top: 0.5rem;
    cursor: pointer;
  }

  .slick-dots > li > button {
    outline: none;
    border: 3px solid white;
    border-radius: 100%;
    background-color: white;
    padding: 0;
    height: 0.75rem;
    width: 0.75rem;
    color: transparent;
    /* opacity: 0.5; */
    cursor: pointer;
  }

  .slick-dots > li.slick-active > button {
    opacity: 1;
    border: 3px solid white;
    background-color: transparent;
    height: 1.15rem;
    width: 1.15rem;
  }
`

class Hero extends React.Component {
  componentDidMount = () => {
    // Check for Safari (desktop and iOS)
    // See here (will have to keep this test updated): https://stackoverflow.com/questions/7944460/detect-safari-browser
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      !navigator.userAgent.match('CriOS')
    // console.log(isSafari)

    if (isSafari) {
      const slickTracks = document.querySelectorAll('.slick-track')
      slickTracks.forEach(slickTrack => {
        slickTrack.style.marginLeft = '-1px'
        // console.log(slickTrack)
      })
    }
  }

  render() {
    // Make a new copy of the slides array (so I can add images to the slides)
    const slidesWithImages = [...slides]

    // Add the images from the heroImages query to slidesWithImages
    slidesWithImages.forEach((item, index) => {
      item.image = this.props.fluid[index]
    })

    // Configure [react-slick]
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
      <section id="hero">
        <StyledSlick {...settings}>
          {slidesWithImages.map(item => {
            return <Slide key={item.id} item={item} />
          })}
        </StyledSlick>
      </section>
    )
  }
}

export default Hero
