import React from 'react'
import Slick from 'react-slick'

import styled, { createGlobalStyle } from 'styled-components'
// import { colors, styles } from '../../utils/theme'

import FaPlay from 'react-icons/lib/fa/play'
import Lightbox from 'react-image-lightbox'

import BgImage from '../BgImage'
import SlickArrow from './SlickArrow'

// Make all images fill 100% of the height of .slick-track (which uses display: flex)
const ImageWrapper = styled.div`
  flex: auto;
  display: flex;

  .gatsby-image-wrapper {
    flex: auto;
    display: flex;
  }
  .gatsby-image-wrapper {
    flex: auto;
  }
`

// Custom styles for [react-slick]
const StyledSlick = styled(Slick)`
  .slick-track {
    display: flex !important;
    height: 20rem !important;
    max-height: 95vh !important;
  }

  .slick-slide {
    display: flex !important;
    height: 100% !important; /* change back to auto...? */
  }
`

// Custom styles for [react-image-lightbox] - injecting globally because the plugin doesn't seem to pass this.props.className to className...
createGlobalStyle`
	/* .ril__outer.ril__outer {
		background-color: rgba(255, 255, 255, 0.85);
  } */

	.ril__navButtons.ril__navButtons {
		border: none;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 20px;
		height: 34px;
		padding: 40px 30px;
		margin: auto;
		cursor: pointer;
		opacity: 0.7;
	}

	.ril__navButtonPrev.ril__navButtonPrev {
		outline: 0;
		background-color: rgba(0, 0, 0, 0.5);
		/* left: 0;
	background: rgba(0, 0, 0, 0.2)
		url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==)
		no-repeat center; */
	}

	.ril__navButtonNext.ril__navButtonNext {
		outline: 0;
		background-color: rgba(0, 0, 0, 0.6);
		/* right: 0;
	background: rgba(0, 0, 0, 0.2)
		url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+)
		no-repeat center; */
	}

	.ril__toolbar.ril__toolbar {
		background-color: transparent;
		/* position: absolute;
	left: 0;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between; */
	}

	/* .ril__builtinButton {
    width: 40px;
    height: 35px;
    cursor: pointer;
    border: none;
    opacity: 0.7;
  } */

	/* .ril__closeButton {
    background-size: contain;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=)
      no-repeat center; 
  } */

	/* .ril__caption.ril__caption {
    background-color: rgba(255, 255, 255, 0.5);
    color: hsl(0, 0%, 0%, 0.8); 
  } */

  .ril__captionContent { margin: 0 auto; }
`

// Template for each slider
class CategorySlider extends React.Component {
  state = {
    allSlides: this.props.items,
    imageSlides: [],
    currentIndex: -1
  }

  componentWillMount = () => {
    this.getLightboxSlides()
  }

  componentDidMount = () => {
    // Check for Safari (desktop and iOS)
    // See here (will have to keep this test updated): https://stackoverflow.com/questions/7944460/detect-safari-browser
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      !navigator.userAgent.match('CriOS')
    // console.log(isSafari)

    if (isSafari) {
      const slickTracks = document.querySelectorAll('.slick-track')
      slickTracks.forEach(slickTrack => {
        slickTrack.style.marginLeft = '-1px'
        // console.log(slickTrack)
      })
    }
  }

  getLightboxSlides = () => {
    // Skip this for the video slider (it has no lightbox)
    if (this.props.category != 'video') {
      let offset = 0
      let slidesWithOffset = [...this.state.allSlides]

      // Add an "offset" property that tracks how many non-image slides have come before each image slides (important for opening the right image in the lightbox later). Only affects onstage slides.
      slidesWithOffset.forEach((slide, index) => {
        if (slide.title) {
          offset += 1
        } else {
          slide.offset = offset
        }
      })

      // Filter any non-image slides for the lightbox array
      const lightboxSlides = slidesWithOffset.filter((slide, index) => slide.image)

      this.setState({
        allImages: slidesWithOffset,
        imageSlides: lightboxSlides
      })
    }
  }

  handleClickImage = (e, index) => {
    // Take the index of the clicked allSlides image and subtract the number of non-image slides that have come before it to figure out which imagesSlides image to start with in the lightbox (affects onstage slides only)
    let offset = this.state.allSlides[index].offset
    this.setState({ currentIndex: index - offset })
  }

  handleClickVideo = (e, index) => {
    // Remove the image placeholder and load the YouTube video on click
    if (this.props.category === 'video') {
      // console.log('Video slide index: ' + index)
      let updatedSlides = [...this.state.allSlides]
      updatedSlides[index].showVideo = true
      // console.log('Show video: ' + updatedSlides[index].showVideo)
      this.setState({ allSlides: updatedSlides })
      // console.log()
    }
  }

  handleCloseLightbox = () => this.setState({ currentIndex: -1 })

  render() {
    const { allSlides, imageSlides, currentIndex } = this.state
    let settings = {}

    if (this.props.category == 'video') {
      settings = {
        infinite: false, // to deal with cloned slides causing two videos to play
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SlickArrow next />,
        prevArrow: <SlickArrow prev />,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    } else {
      settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SlickArrow next />,
        prevArrow: <SlickArrow prev />,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }

    return (
      <div>
        <StyledSlick {...settings}>
          {allSlides.map((slide, index) => {
            return (
              <div key={slide.id}>
                {slide.hasImage && (
                  <ImageWrapper onClick={e => this.handleClickImage(e, index)}>
                    <BgImage
                      fluid={slide.image.node.fluid}
                      imgStyle={{ objectPosition: slide.imagePos }}
                    />
                  </ImageWrapper>
                )}
                {slide.video && (
                  <div className="flex-auto self-center">
                    {!slide.showVideo && (
                      <div
                        onClick={e => this.handleClickVideo(e, index)}
                        className="aspect-ratio aspect-ratio--16x9 pointer"
                      >
                        <ImageWrapper className="aspect-ratio--object">
                          <BgImage
                            fluid={slide.image.node.fluid}
                            alt={slide.altText}
                            title={slide.altText}
                            imgStyle={{ objectPosition: slide.imagePos }}
                          />
                        </ImageWrapper>
                        <div className="absolute top-50 left-50 transXY--50 z-999 br3 bg-gold ph2 pv1">
                          <FaPlay className="pl1 f2 white" />
                        </div>
                      </div>
                    )}
                    {slide.showVideo && (
                      <div className="aspect-ratio aspect-ratio--16x9">
                        {/* <div className="aspect-ratio--object"> */}
                        {slide.video}
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                )}
                {slide.title && (
                  <div className="flex-auto flex items-end bg-white pa4 w-100 h-100">
                    <div className="flex-auto pl1">
                      <h4 className="mb2 lh-title f2">{slide.title}</h4>
                      <h5 className="f4 fw3 gold">
                        {slide.role && <span>{slide.role} - </span>}
                        {slide.company}
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </StyledSlick>

        {currentIndex >= 0 && (
          <Lightbox
            mainSrc={imageSlides[currentIndex].image.node.fixed.src}
            nextSrc={
              imageSlides[(currentIndex + 1) % imageSlides.length].image.node.fixed
                .src
            }
            prevSrc={
              imageSlides[
                (currentIndex + imageSlides.length - 1) % imageSlides.length
              ].image.node.fixed.src
            }
            imageCaption={imageSlides[currentIndex].altText}
            onCloseRequest={this.handleCloseLightbox}
            onMovePrevRequest={() =>
              this.setState({
                currentIndex:
                  (currentIndex + imageSlides.length - 1) % imageSlides.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentIndex: (currentIndex + 1) % imageSlides.length
              })
            }
            enableZoom={false}
            animationOnKeyInput={true}
            reactModalStyle={{ width: '500px !important' }}
          />
        )}
      </div>
    )
  }
}

export default CategorySlider
