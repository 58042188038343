import React from 'react'
import { graphql } from 'gatsby'

import Base from '../components/Base'
import Hero from '../components/Hero/Hero'
import NavBar from '../components/NavBar'
import Reviews from '../components/Reviews/Reviews'
import News from '../components/News/News'
import Bio from '../components/Bio'
import Calendar from '../components/Calendar/Calendar'
import Media from '../components/Media/Media'
import Contact from '../components/Contact'

function IndexPage({ data }) {
  return (
    <Base>
      <Hero fluid={data.heroImages.edges} />
      <NavBar menuBg={data.menuBg.fluid} menuLogo={data.menuLogo.fluid} />
      <News fluid={data.newsImages.edges} />
      <Reviews />
      <Bio fluid={data.bioImages.edges} />
      <Calendar
      // fluid={data.calendarImages.edges}
      />
      <Media
        onstagefluid={data.onstageImages.edges}
        offstagefluid={data.offstageImages.edges}
        videofluid={data.videoImages.edges}
        portraitfluid={data.portraitImages.edges}
      />
      <Contact fluid={data.contactImages.edges} />
    </Base>
  )
}

export default IndexPage

// IndexPage images
export const query = graphql`
  query {
    heroImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/hero-/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    menuBg: imageSharp(fluid: { originalName: { regex: "/menu-bg.jpg/" } }) {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }

    menuLogo: imageSharp(fluid: { originalName: { regex: "/menu-logo.png/" } }) {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }

    newsImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/news/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 925, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    bioImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/bio/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    # calendarImages: allImageSharp(
    #   filter: { fluid: { originalName: { regex: "/calendar/" } } }
    #   sort: { fields: [id], order: ASC }
    # ) {
    #   edges {
    #     node {
    #       fluid(maxWidth: 1000) {
    #         ...GatsbyImageSharpFluid
    #       }
    #     }
    #   }
    # }

    onstageImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/onstage/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 3000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    offstageImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/offstage/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 1500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    videoImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/video/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 1500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    portraitImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/portrait/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 1500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    contactImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/contact/" } } }
      sort: { fields: [fluid___originalName], order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
