const Events = ({ items }) => (
  <FlipMove typeName="ul" duration="300" className="ml0 list w-80-l">
    {items.map(item => {
      return (
        <li key={item.node.season + item.node.date}>
          <Event item={item.node} />
        </li>
      )
    })}
  </FlipMove>
)

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import FlipMove from 'react-flip-move'

import Event from './Event'

export default Events
