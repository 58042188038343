const Slide = props => {
  // First, separate my non-Slick props from the Slick props passed to the slide wrapper (to solve error message).
  const { item, ...slickProps } = props

  // Then, pass the Slick props to the slide and use my non-Slick props separately. See: https://github.com/akiran/react-slick/issues/728
  return (
    <div {...slickProps}>
      <div className="flex-auto flex relative vh-75">
        <ImageWrapper>
          <BgImage
            fluid={item.image.node.fluid}
            alt={item.altText}
            title={item.altText}
            imgStyle={{ objectPosition: item.bgPos }}
          />
        </ImageWrapper>
        <div className="absolute top-0 left-0 z-1 w-100 h-100 white">
          <div
            className={
              styles.container +
              ' flex justify-start items-end pv3 pv4-ns w-100 h-100'
            }
          >
            <div>{item.info}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////

// TODO: include these styles directly in BgImage instead? (Activate via props?) Turn this into a version of the BgImage?
// Make all images fill 100% of the height of .slick-track
const ImageWrapper = styled.div`
  flex: auto;
  display: flex;

  .gatsby-image-wrapper {
    flex: auto;
    display: flex;
  }
  .gatsby-image-wrapper {
    flex: auto;
  }
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import BgImage from '../BgImage'
import { styles } from '../../utils/theme'

export default Slide
