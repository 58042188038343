import React from 'react'
import Image from 'gatsby-image'

import { colors, styles } from '../utils/theme'

import Angle from './Angle'
import Heading from './Heading'
import FormNetlify from './FormNetlify'

const Contact = props => {
  const contactImage = props.fluid[1].node.fluid
  const opera5Logo = props.fluid[0].node.fluid

  return (
    <section id="contact" className={styles.section + 'relative'}>
      <div className={styles.container}>
        <Heading
          main="Contact Rachel"
          sub="Get in touch and learn more about Opera 5"
        />
        <div className="flex-l justify-between-l">
          <div className="mb4 mb0-l w-25-l">
            <div className="center w-75 mw5">
              <Image
                fluid={contactImage}
                alt="Portrait of Rachel Krehm by Hayley Andoff Photography"
                title="Portrait of Rachel Krehm by Hayley Andoff Photography"
              />
            </div>
            <div className="flex flex-column items-center">
              <div className="pt3 pb1">
                <span className="dib w1 b">t.</span>
                <a
                  href="tel:647-248-4048"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkGold}
                >
                  647.248.4048
                </a>
              </div>
              <div className="pb3">
                <span className="dib w1 b">e.</span>
                <a
                  href="email:rachel@rachelkrehm.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkGold}
                >
                  rachel@rachelkrehm.com
                </a>
              </div>
            </div>
            <div className="center w-60 mw4">
              <a
                href="http://opera5.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image fluid={opera5Logo} title={props.title} />
              </a>
            </div>
          </div>
          <div className="pl5-l w-75-l">
            <FormNetlify />
          </div>
        </div>
      </div>
      <Angle bottom right color={colors.coffee} />
    </section>
  )
}

export default Contact
