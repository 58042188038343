import React from 'react'

import styled from 'styled-components'

const Wrapper = styled.div``

const Review = props => {
	// First, separate my non-Slick props from the Slick props passed to the slide wrapper (to solve error message).
	const { review, ...otherProps } = props

	// Then, pass the Slick props to the slide and use my non-Slick props separately.
	// See: https://github.com/akiran/react-slick/issues/728
	return (
		<Wrapper
			{...otherProps}
			className="flex flex-column justify-center items-center pt4 pt5-ns pb2 pb4-ns tc gold">
			<blockquote className="pb2-ns measure f4 lh-title fw3">
				{review.quotation}
				<cite className="db pt2 f5 o-50 fs-normal">{review.source}</cite>
			</blockquote>
		</Wrapper>
	)
}

export default Review
