import React from 'react'
import FaClose from 'react-icons/lib/fa/close'
import Image from 'gatsby-image'
import Scroll from 'react-scroll'
const ScrollLink = Scroll.Link

import styled from 'styled-components'
import { styles } from '../utils/theme'

import BgImage from './BgImage'
import SocialLinks from './SocialLinks'

// Link data
const scrollLinks = [
  { id: 'news', text: 'Artist News' },
  { id: 'bio', text: 'About Rachel' },
  { id: 'calendar', text: 'Calendar & Upcoming' },
  { id: 'media', text: 'Media & Image' },
  { id: 'contact', text: 'Contact Rachel' }
]

const Wrapper = styled.div`
  /* Make background image height adjust to overlay content height */
  /* TODO: add this to Playground & Github issue */
  & > div.gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

// NOTE: This is the content of <Modal /> (used in layouts/index.js and NavBar.js)
const MenuOverlay = props => (
  <Wrapper className="relative h-100">
    <BgImage height="100%" fluid={props.menuBg} />
    <div
      onClick={props.hideModal}
      className="flex flex-column justify-center items-center relative top-0 left-0 z-1 bg-black-50 w-100 h-100"
    >
      <div className="relative tc ph3 pv4 w-100 overflow-scroll touch-scroll">
        <div className="dn db-ns absolute-ns top-0-ns left-1-ns left-2-l">
          <FaClose
            onClick={props.hideModal}
            className={styles.linkSand + ' lh-solid f2'}
          />
        </div>
        <div className="center w-90 mw5">
          <Image fluid={props.menuLogo} />
        </div>
        <ul className="ml0 pv4 list">
          {scrollLinks.map(link => (
            <li className="pv1" key={link.id}>
              <ScrollLink
                onClick={props.hideModal}
                to={link.id}
                smooth="easeInOutQuart"
                duration={1750}
                className={styles.linkSand + ' dib pv2 oswald f2'}
              >
                {link.text}
              </ScrollLink>
            </li>
          ))}
        </ul>
        <SocialLinks linkStyles={styles.linkSand + ' ph1 lh-solid'} />
        <p className="pt4 pb2 f6 white">
          &copy; 2017 Rachel Krehm. All rights reserved.
        </p>
        <p className="f7 white">
          Built by{' '}
          <a
            href="http://www.coffeeshopcreative.ca"
            target="_blank"
            rel="nofollow"
            className={styles.linkWhite}
          >
            Coffeeshop Creative
          </a>
          .
        </p>
        {/* <p className="pt3 f6 white">
					&copy; 2017 Rachel Krehm. All rights reserved. Built by{' '}
					<a
						href="http://www.coffeeshopcreative.ca"
						target="_blank"
						rel="nofollow"
						className={styles.linkWhite}>
						Coffeeshop Creative
					</a>.
				</p> */}
      </div>
    </div>
  </Wrapper>
)

export default MenuOverlay
