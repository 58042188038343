import React from 'react'

import Angle from '../Angle'
import SlidersContainer from './SlidersContainer'
import Heading from '../Heading'

import onstageSlides from '../../data/mediaOnstage'
import offstageSlides from '../../data/mediaOffstage'
import videoSlides from '../../data/mediaVideo'
import portraitSlides from '../../data/mediaPortrait'

import { styles } from '../../utils/theme'

// Main component
const Media = ({ onstagefluid, offstagefluid, videofluid, portraitfluid }) => {
  // Make new copies of the slide data
  const onstageSlidesWithImages = [...onstageSlides]
  const offstageSlidesWithImages = [...offstageSlides]
  const videoSlidesWithImages = [...videoSlides]
  const portraitSlidesWithImages = [...portraitSlides]

  // Track any skipped array elements in the loops below (so no images get missed)
  let onstageIndexOffset = 0

  // Add the images from the onstageImages query to onstageSlidesWithImages
  onstageSlidesWithImages.forEach((item, index) => {
    if (item.hasImage) {
      item.image = onstagefluid[index - onstageIndexOffset]
    } else {
      onstageIndexOffset += 1
    }
  })

  // Add the images from the offstageImages query to offstageSlidesWithImages
  offstageSlidesWithImages.forEach((item, index) => {
    item.image = offstagefluid[index]
  })

  // Add the images from the videoImages query to videoSlidesWithImages
  videoSlidesWithImages.forEach((item, index) => {
    item.image = videofluid[index]
  })

  // Add the images from the portraitImages query to portraitSlidesWithImages
  portraitSlidesWithImages.forEach((item, index) => {
    item.image = portraitfluid[index]
  })

  return (
    <section id="media" className={styles.section + ' relative bg-near-white'}>
      <div className={styles.container}>
        <Heading main="Media &amp; Image" sub="Photos, Videos &amp; Multimedia" />
      </div>
      <SlidersContainer
        onstageSlidesWithImages={onstageSlidesWithImages}
        offstageSlidesWithImages={offstageSlidesWithImages}
        videoSlidesWithImages={videoSlides}
        portraitSlidesWithImages={portraitSlidesWithImages}
      />
      <Angle bottom right color="white" />
    </section>
  )
}

export default Media
