import shortid from 'shortid'

// TODO: when I add a new image to the images/media/portait folder, I also need to add a line for it here:

const portraitSlides = [
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 13%',
    altText: 'Image by Hayley Andoff Photography'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 35%',
    altText: 'Image by Hayley Andoff Photography'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText: 'Image by Hayley Andoff Photography'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText: 'Image by Hayley Andoff Photography'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 50%',
    altText: 'Image by Hayley Andoff Photography'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 25%',
    altText: 'Image by Hayley Andoff Photography'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 15%',
    altText: 'Image by Hayley Andoff Photography'
  },
  {
    id: shortid.generate(),
    hasImage: true,
    imagePos: '50% 30%',
    altText: 'Image by Hayley Andoff Photography'
  }
]

export default portraitSlides
