function EventsBySeason({ events }) {
  const [season, setSeason] = useState('2023')

  const eventsInCurrentSeason = events.filter(
    event => event.node.season === season
  )

  return (
    <div className="flex-l justify-between-l">
      <Filters setSeason={setSeason} className="w-20-l" />
      <Events items={eventsInCurrentSeason} />
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////

import React, { useState } from 'react'

import Events from './Events'
import Filters from './Filters'

export default EventsBySeason
