import React from 'react'
import styled from 'styled-components'

import FaChevronRight from 'react-icons/lib/fa/chevron-right'
import FaChevronLeft from 'react-icons/lib/fa/chevron-left'

// import BgImage from '../BgImage'

import { colors } from '../../utils/theme'

// TODO: save these Slick arrow styles :)
// TODO: is there a way to write these styles once (maybe with .extends()?)

const PrevArrow = styled(FaChevronLeft)`
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 100%;
  background-color: white;
  padding: 0.5rem 0.7rem 0.5rem 0.5rem;
  width: 3rem;
  height: 3rem;
  color: ${colors.gold};
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:focus,
  &:hover {
    background-color: ${colors.gold};
    color: white;
  }
`

const NextArrow = styled(FaChevronRight)`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 100%;
  background-color: white;
  padding: 0.5rem 0.5rem 0.5rem 0.7rem;
  width: 3rem;
  height: 3rem;
  color: ${colors.gold};
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:focus,
  &:hover {
    background-color: ${colors.gold};
    color: white;
  }
`

const SlickArrow = ({ prev, next, onClick }) => {
  if (prev) {
    return <PrevArrow onClick={onClick} />
  }
  if (next) {
    return <NextArrow onClick={onClick} />
  }
}

export default SlickArrow
